import { lazy, Suspense } from "react";
import Loader from "./layouts/loader/Loader";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { HashRouter, useRoutes } from "react-router-dom";
import Footer from "./layouts/Footer.jsx";

const LazyLoad = ({ Comp }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const loggedIn = sessionStorage.getItem("loggedIn");
    if (!loggedIn) {
      navigate("/login");
    }
  }, [navigate]);
  return (
    <Suspense fallback={<Loader />}>
      <Comp />
    </Suspense>
  );
};

/****Layouts*****/
const FullLayout = lazy(() => import("./layouts/FullLayout.js"));

/***** Pages ****/

const Login = lazy(() => import("./views/auth/Login.js"));
const Logout = lazy(() => import("./views/auth/Logout.jsx"));
// const Register = lazy(() => import("./views/auth/Register.jsx"));

const Starter = lazy(() => import("./views/Starter.js"));
const UsersFront = lazy(() => import("./views/users/UserFront.js"));
const UsersApp = lazy(() => import("./views/users/UserApp.js"));


const HumanResources = lazy(() => import("./views/rh/HumanResources.js"));
const Employes = lazy(() => import("./views/rh/Employees.js"));
const Atendance = lazy(() => import("./views/rh/Atendances.js"));
const Evaluations = lazy(() => import("./views/rh/Evaluations.js"));

const Maps = lazy(() => import("./views/monitor/Maps.js"));
const UserMap = lazy(() => import("./views/monitor/UserMap.js"));
const UserAppMap = lazy(() => import("./views/monitor/UserAppMap.js"));

const Vehicles = lazy(() => import("./views/vehicles/VehicleControl.js"));

const Events = lazy(() => import("./views/eventmgr/EventManagement.js"));

const Carto = lazy(() => import("./views/carto/carto.js"));

const Alerts = lazy(() => import("./views/ui/Alerts.js"));
const Badges = lazy(() => import("./views/ui/Badges"));
const Breadcrumbs = lazy(() => import("./views/ui/Breadcrumbs"));
const Buttons = lazy(() => import("./views/ui/Buttons"));
const About = lazy(() => import("./views/About.js"));
const Cards = lazy(() => import("./views/ui/Cards"));
const Forms = lazy(() => import("./views/ui/Forms"));
const Grid = lazy(() => import("./views/ui/Grid"));
const Tables = lazy(() => import("./views/ui/Tables"));

const Router = () => {
  const r = useRoutes([
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/",
      element: <FullLayout />,
      children: [
        { path: "/", element: <LazyLoad Comp={Starter} /> },
        { path: "/starter", element: <LazyLoad Comp={Starter} /> },
        { path: "/usersfront", element: <LazyLoad Comp={UsersFront} /> },
        { path: "/usersapp", element: <LazyLoad Comp={UsersApp} /> },
        { path: "/about", element: <LazyLoad Comp={About} /> },
        { path: "/alerts", element: <LazyLoad Comp={Alerts} /> },
        { path: "/badges", element: <LazyLoad Comp={Badges} /> },
        { path: "/buttons", element: <LazyLoad Comp={Buttons} /> },
        { path: "/cards", element: <LazyLoad Comp={Cards} /> },
        { path: "/grid", element: <LazyLoad Comp={Grid} /> },
        { path: "/table", element: <LazyLoad Comp={Tables} /> },
        { path: "/forms", element: <LazyLoad Comp={Forms} /> },
        { path: "/breadcrumbs", element: <LazyLoad Comp={Breadcrumbs} /> },
        { path: "/logout", element: <LazyLoad Comp={Logout} /> },

        { path: "/humanresources", element: <LazyLoad Comp={HumanResources} /> },
        { path: "/employes", element: <LazyLoad Comp={Employes} /> },
        { path: "/atendance", element: <LazyLoad Comp={Atendance} /> },
        { path: "/evaluations", element: <LazyLoad Comp={Evaluations} /> },

        { path: "/maps", element: <LazyLoad Comp={Maps} /> },
        { path: "/usermap", element: <LazyLoad Comp={UserMap} /> },
        { path: "/userappmap", element: <LazyLoad Comp={UserAppMap} /> },
        
        { path: "/vehicles", element: <LazyLoad Comp={Vehicles} /> },

        { path: "/events", element: <LazyLoad Comp={Events} /> },
        
        { path: "/carto", element: <LazyLoad Comp={Carto} /> },
      ],
    },
  ]);

  return r;
};

export default function App() {
  return (
    <HashRouter>
      <div className="dark">
        <Outlet />
        <Router />
      </div>

      <Footer />
    </HashRouter>
  );
}
